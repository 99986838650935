import React, { useContext, useState } from "react"
import { navigate } from '@reach/router'

import Helmet from "react-helmet"
import swal from 'sweetalert2'
import Loader from 'react-loader-spinner'

import Header from '../components/Header'


import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import map from '../assets/images/map.png'
import { ContainerSection, TextSection } from "../components/Section"
import EmbedMap from "../components/EmbedMap"
import Link from '../components/Link'
import Newsletter from "../components/Newsletter"
import BannerContext from "../lib/context"
import ContactImage from '../assets/images/find-us/4.png'
import Slideshow from "../components/Slideshow"
import { sendMessage } from "../lib/API"




export default () => {
    const siteTitle = "Blackheath and Charlton Baptist Church - Contact"
    const iconStyle = { }
    const headerStyle = { }
    const contactStyle = { fontWeight: 500 }

    // const pageData = useContext( BannerContext )[pageName]
    const pageName = 'contact-banners'

    // const { header, banners } = pageData || { header: null, banners: null }
    const headerImages = [ ContactImage ]
    const headerTitle = 'Contact Us'
    // const headerIcon = <span className="icon major"><i className="fas fa-phone-alt"></i></span>
    const headerButtons = [
        { url: "/events#one", text: "View Events" }
    ]

    const [ loading, setLoading ] = useState( false )
    const [ firstName, setFirstName ] = useState( '' )
    const [ lastName, setLastName ] = useState( '' )
    
    const [ email, setEmail ] = useState( '' )
    const [ subject, setSubject ] = useState( '' )
    const [ message, setMessage ] = useState( '' )
    const [ consent, setConsent ] = useState( false )

    const send = async e => {
        e.preventDefault()
        e.stopPropagation()
        setLoading( true )
        const data = { firstName, lastName, email, subject, message }
        const res = await sendMessage( data )
        setLoading( false )
        
        if( res.sent ) {
            swal.fire( 'Success', 'Message sent successfully', 'success' ).then( () => {
                setFirstName( '' )
                setLasttName( '' )
                setEmail( '' )
                setSubject( '' )
                setMessage( '' )
                setConsent( false )
            } )
            
        }
        else{
            swal.fire( 'Could not send message', 'Please try again later', 'error' )
        }
    }

    const changeValue = ( e, handler ) => handler( e.target.value ) 
    

    return (
            
        <div id="contact-page">
            <Helmet title={siteTitle} />
            <Header images={headerImages} headerTitle={headerTitle} tint={0.4} headerButtons={headerButtons} />
            {/* <Slideshow images={[ ContactImage ]} tint={0.3} />          */}

            {/* <section id="info" className="main style1 special">
                <div className="col-12 contact-details">
                    <div className="grid-wrapper">
                        <div className="col-4 clickable" onClick={() => typeof window !== 'undefined' ? window.location.replace( 'mailto:admin@bcbchurch.co.uk' ) : null}>
                            <header className="major">
                                <h2 style={headerStyle}>Email</h2>
                            </header>
                            <span className="icon major"><i className="fa fa-envelope" style={iconStyle} /></span>
                            <p style={contactStyle}>admin@bcbchurch.co.uk</p>
                        </div>
                        <div className="col-4">
                            <header className="major">
                                <h2 style={headerStyle}>Phone</h2>
                            </header>
                            <span className="icon major"><i className="fa fa-phone" style={iconStyle} /></span>
                            <p style={contactStyle}>020 8856 8654</p>
                        </div>
                        <div className="col-4 clickable" onClick={() => navigate( '/contact/#contact-map' )}>
                            <header className="major">
                                <h2 style={headerStyle}>Address</h2>
                            </header>
                            <span className="icon major"><i className="fa fa-map-marker-alt" style={iconStyle} /></span>
                            <p style={contactStyle}>Blackheath & Charlton Baptist Church <br />  Marlborough Lane <br /> London, SE7 7DF</p>

                        </div>
                    </div>
                </div>
            </section> */}

            <TextSection
                id="contact-message"
                title="We'd love to hear from you, send us a message"
                description=""
            />

            <section id="form" method='POST' className="main style1 special" onSubmit={send} >
                <div className="grid-wrapper">
                    <div className="col-12 contact-form">
                        <form>
                        <div className="grid-wrapper">
                            <div className="col-12">
                                {/* <header className="major">
                                    <h2 style={headerStyle}>Send a Message</h2>
                                </header> */}
                            </div>
                            <div className="col-6" style={{textAlign: 'left'}}>
                                <label>First Name</label>
                                <input className="form-input" type="text" name="first-name" placeholder="First Name*" required value={firstName} onChange={e => changeValue( e, setFirstName )} />
                            </div>
                            <div className="col-6" style={{textAlign: 'left'}}>
                                <label>Last Name</label>
                                <input className="form-input" type="text" name="last-name" placeholder="Last Name*" required value={lastName} onChange={e => changeValue( e, setLastName )} />
                            </div>

                            <div className="col-12" style={{ marginTop: 40 }} style={{textAlign: 'left'}}>
                                <label>Email</label>
                                <input className="form-input email" type="email" name="email" placeholder="Email*" required value={email} onChange={e => changeValue( e, setEmail )} />
                            </div>

                            <div className="col-12" style={{ marginTop: 60, textAlign: 'left'}}>
                                <label>I'd like to contact BCB church because... *</label>
                                <input className="form-input" type="radio" required id="option1" name="subject" value="Reason 1" onChange={e => changeValue( e, setSubject )} />
                                <label for="option1">Reason 1</label><br />

                                <input className="form-input" type="radio" required id="option2" name="subject" value="Reason 2" onChange={e => changeValue( e, setSubject )} />
                                <label for="option2">Reason 2</label><br />

                                <input className="form-input" type="radio" required id="option3" name="subject" value="Reason 3" onChange={e => changeValue( e, setSubject )} />
                                <label for="option3">Reason 3</label><br />

                                {/* <input className="form-input" type="text" name="subject" placeholder="Subject*" required value={subject} onChange={e => changeValue( e, setSubject )} /> */}
                            </div>

                            {/* <div className="col-12" style={{ marginTop: 40 }}>
                                <input className="form-input" type="text" name="subject" placeholder="Subject*" required value={subject} onChange={e => changeValue( e, setSubject )} />
                            </div> */}

                            <div className="col-12" style={{ marginTop: 40, textAlign: 'left' }}>
                                <label>Additional Message</label>
                                <textarea rows={5} autoComplete={false} placeholder="Anything else you would like to tell us:" value={message} className="form-input" style={{ resize: "none" }} onChange={e => changeValue( e, setMessage )}/>
                            </div>

                            <div className="col-12" style={{ marginTop: 60, textAlign: 'left'}}>
                                <label>GDPR consent*</label>
                                <input className="form-input" type="checkbox" id="consent" required name="consent" checked={consent} onChange={e => changeValue( e, setConsent )} />
                                <label for="consent">I am happy for BCBChurch to store my data for communication purposes</label><br />
                            </div>
                        </div>
                            

                            <br />{!loading ? <input type="submit" value="Submit" className="button special" /> :
                            <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={40}
                                width={50}
                            />}
                        </form>
                    </div>


                    
                  


                </div>
            </section>
            

             
            {/* <section data-aos="fade-up" id="contact-map" className="main style1">
                <div className="grid-wrapper">
                    <div className="col-6">
                        <header className="major">
                            <h2>Our Location</h2>
                        </header>
                        <p>We are located in Greenwich</p>
                    </div>
                    <div data-aos="zoom-in" className="col-6">
                        <EmbedMap />
                    </div> 
                </div>
            </section> */}

            <section data-aos="fade-up" id="map" className="main style1">
                 <EmbedMap /> 
            </section>


{/* 

            <ContainerSection id='contact-map'>
                <EmbedMap />
            </ContainerSection> */}

            {/* <ContainerSection>
                <header className="major"><h2>Newsletter</h2></header>
                <Newsletter />
            </ContainerSection> */}

            {/* <section id="two" className="main style1">
                <div className="grid-wrapper">

                    <div className="col-12">
                        <span className="image fit"><img src={pic01} alt="" /></span>
                    </div>
                </div>
            </section> */}


            {/* <section id="two" className="main style2">
                <div className="grid-wrapper">
                    <div className="col-6">
                        <ul className="major-icons">
                            <li><span className="icon style1 major fa-code"></span></li>
                            <li><span className="icon style2 major fa-bolt"></span></li>
                            <li><span className="icon style3 major fa-camera-retro"></span></li>
                            <li><span className="icon style4 major fa-cog"></span></li>
                            <li><span className="icon style5 major fa-desktop"></span></li>
                            <li><span className="icon style6 major fa-calendar"></span></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <header className="major">
                            <h2>Lorem ipsum dolor adipiscing<br />
                            amet dolor consequat</h2>
                        </header>
                        <p>Adipiscing a commodo ante nunc accumsan interdum mi ante adipiscing. A nunc lobortis non nisl amet vis volutpat aclacus nascetur ac non. Lorem curae eu ante amet sapien in tempus ac. Adipiscing id accumsan adipiscing ipsum.</p>
                        <p>Blandit faucibus proin. Ac aliquam integer adipiscing enim non praesent vis commodo nunc phasellus cubilia ac risus accumsan. Accumsan blandit. Lobortis phasellus non lobortis dit varius mi varius accumsan lobortis. Blandit ante aliquam lacinia lorem lobortis semper morbi col faucibus vitae integer placerat accumsan orci eu mi odio tempus adipiscing adipiscing adipiscing curae consequat feugiat etiam dolore.</p>
                        <p>Adipiscing a commodo ante nunc accumsan interdum mi ante adipiscing. A nunc lobortis non nisl amet vis volutpat aclacus nascetur ac non. Lorem curae eu ante amet sapien in tempus ac. Adipiscing id accumsan adipiscing ipsum.</p>
                    </div>
                </div>
            </section> */}

        </div>
    )
}

